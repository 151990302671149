/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const React = require("react")
const { FirebaseProvider } = require("./src/context/FirebaseProvider")
const { AppProvider } = require("./src/context")
const { I18nextProvider } = require("react-i18next")
const Sentry = require("@sentry/react")
const i18n = require("./src/utils/i18n").default

Sentry.init({
  dsn: "https://05e9ee9cdfcd471b88f44d34b344c720@o4504615611596800.ingest.us.sentry.io/4509077868052480",
  environment: process.env.GATSBY_ACTIVE_ENV,
})

exports.wrapRootElement = ({ element }) => {
  return (
    <AppProvider>
      <I18nextProvider i18n={i18n}>
        <FirebaseProvider>{element}</FirebaseProvider>
      </I18nextProvider>
    </AppProvider>
  )
}

/* exports.onClientEntry = () => {
  if (process.env.NODE_ENV !== "production") {
    const whyDidYouRender = require("@welldone-software/why-did-you-render")
    whyDidYouRender(React, {
      trackAllPureComponents: true,
    })
  }
} */
