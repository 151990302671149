import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'da',
    debug: process.env.NODE_ENV === 'development',

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },

    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export function getTranslationText(key: string, lang: string | undefined): string {
  if (lang) {
    const normalizedLang = lang.length > 2
      ? lang.trim().slice(0, 2).toLowerCase()
      : lang.trim().toLowerCase();
    return i18n.t(key, { lng: normalizedLang });
  }
  return i18n.t(key);
}

export default i18n;