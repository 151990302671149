/* Action types */
import { ActionMap, createActions } from "../../lib/reducer.types"
import { MenuTypeEnum, OffCanvasTypeEnum } from "./app.types"

export enum GlobalAppTypes {
  ToggleMenu = "TOGGLE_MENU",
  ToggleOffCanvas = "TOGGLE_OFFCANVAS",
  ToggleNav = "TOGGLE_NAV",
  ToggleSettings = "TOGGLE_SETTINGS",
  ToggleSidebar = "TOGGLE_SIDEBAR",
  SetCurrentTime = "SET_CURRENT_TIME",
  SetCurrentPaginationPage = "SET_CURRENT_PAGINATION_PAGE",
  SetBackgroundDataFetched = "SET_BACKGROUND_DATA_FETCHED",
  Reset = "RESET",
}

export type GlobalAppActionMap =
  | {
      type: GlobalAppTypes.ToggleMenu
      payload: {
        key: MenuTypeEnum
      }
    }
  | {
      type: GlobalAppTypes.ToggleOffCanvas
      payload: {
        key: OffCanvasTypeEnum
      }
    }
  | {
      type: GlobalAppTypes.ToggleNav
      payload: {
        toggled?: boolean
      }
    }
  | {
      type: GlobalAppTypes.ToggleSettings
      payload: {
        toggled?: boolean
      }
    }
  | {
      type: GlobalAppTypes.ToggleSidebar
      payload: {
        toggled: boolean
      }
    }
  | {
      type: GlobalAppTypes.SetCurrentTime
      payload: {}
    }
  | {
      type: GlobalAppTypes.SetCurrentPaginationPage
      payload: {
        page: number | null
      }
    }
  | {
      type: GlobalAppTypes.SetBackgroundDataFetched
      payload: {}
    }
  | {
      type: GlobalAppTypes.Reset
      payload: {}
    }

export const GlobalAppActions = createActions<GlobalAppActionMap>()
